import  { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.silverGhost,
    earningToken: serializedTokens.usdt,
    contractAddress: {
      1337: '0x3b80b837c5b2F2B804e9aD3bc86eB0f13126Cb17',
      97: '0x1341A9012379dF8c44b0E21D3CB8FC63E0670b9E',
      56: '0x6B7858367086Be93B410ecE64C28DCCf2299ea4d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.005',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.silverGhost,
    earningToken: serializedTokens.usdt,
    contractAddress: {
      1337: '0x3b80b837c5b2F2B804e9aD3bc86eB0f13126Cb17',
      97: '0x1341A9012379dF8c44b0E21D3CB8FC63E0670b9E',
      56: '0x1055f56a2F1bCF124fF4F7d1c1A7E333345f7368',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.001',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.silverGhost,
    earningToken: serializedTokens.usdt,
    contractAddress: {
      1337: '0x3b80b837c5b2F2B804e9aD3bc86eB0f13126Cb17',
      97: '0x1341A9012379dF8c44b0E21D3CB8FC63E0670b9E',
      56: '0x70b96D391e9333f7a5696C3911F59A46F1ec7fb7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00005',
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools
