import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'USDT-BNB',
    lpAddresses: {
      1337: '0x26a9C118BA17E0b599b9E7b34C0c8A7A3Fb720e8',
      97: '0xF2270C830eab76D29Bd6896A87A10F2Ca368fe03',
      56: '0xBdb8F03aa980DB2027C95e9ef2215AF57CB7Cb23',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wbnb,
    multiplier: '10X',
  }
]

export default farms
