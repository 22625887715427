import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppState } from 'state'
import { getGhostSharePotFactoryContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import GhostSharePotAbi from 'config/abi/GhostSharePot.json'
import { SharePotState, SerializedSharePot } from '../types'

const initialState: SharePotState = {
  data: [],
  userDataLoaded: false,
}

// Async thunks
export const fetchGhostSharePotDataAsync = createAsyncThunk<
  SerializedSharePot[],
  string,
  {
    state: AppState
  }
>('pots/fetchGhostSharePotDataAsync', async (account) => {
  const factory = getGhostSharePotFactoryContract()
  const pots = await factory.getPotAddress()
  const calls = pots.flatMap((address) => {
    return [
      {
        address,
        name: 'collection',
      },
      {
        address,
        name: 'name',
      },
      {
        address,
        name: 'admin',
      },
      {
        address,
        name: 'totalLevel',
      },
      {
        address,
        name: 'pendingReward',
        params: [account],
      },
      {
        address,
        name: 'userLevel',
        params: [account],
      },
    ]
  })
  const results = await multicall(GhostSharePotAbi, calls)
  const potData = results.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 6)
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [pots[chunkIndex]] // start a new chunk
    }
    resultArray[chunkIndex].push(item[0])
    return resultArray
  }, [])

  return potData.map((data) => {
    const [address, collection, name, admin, totalLevel, pendingReward, userLevel] = data
    return {
      address,
      collection,
      name,
      admin,
      totalLevel: totalLevel.toString(),
      pendingReward: pendingReward.toString(),
      userLevel: userLevel.toString(),
    }
  })
})
export const sharePotSlice = createSlice({
  name: 'GhostSharePotFactory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchGhostSharePotDataAsync.fulfilled, (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    })
  },
})

export default sharePotSlice.reducer
