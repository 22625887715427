import { createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type { AppState } from 'state'
import { getGhostPotContract } from 'utils/contractHelpers'
import { GhostPotState } from '../types'

const initialState: GhostPotState = {
  pendingReward: '0',
  userDataLoaded: false,
}

// Async thunks
export const fetchGhostPotDataAsync = createAsyncThunk<string,string,{
  state: AppState
}>(
  'ghostpot/fetchGhostPotDataAsync',
  async (address) => {
    const ghostPotContract = getGhostPotContract();
    const pendingReward = await ghostPotContract.getPendingReward(address)
    return pendingReward.toString()
  }
)
export const ghostPotSlice = createSlice({
  name: 'GhostPot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchGhostPotDataAsync.fulfilled, (state, action) => {
      state.pendingReward = action.payload
    })
  },
})

export default ghostPotSlice.reducer
