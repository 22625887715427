import { ChainId } from '@ghost-nft/ghost-sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { farmsConfig } from 'config/constants'
import { APP_CHAIN_ID } from 'config/constants/tokens'

export const getAddress = (address: Address): string => {
  return address[APP_CHAIN_ID] ?? address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getGhostSquadAddress = () => {
  return getAddress(addresses.ghostSquad)
}
export const getGhostRouterAddress = () => {
  return getAddress(addresses.ghostRouter)
}
export const getGhostPotAddress = () => {
  return getAddress(addresses.ghostPot)
}
export const getGhostNftAddress = () => {
  return getAddress(addresses.ghostNft)
}
export const getGhostCollectionFactoryAddress = () => {
  return getAddress(addresses.ghostCollectionFactory)
}
export const getGhostFeeManagerFactoryAddress = () => {
  return getAddress(addresses.ghostFeeManagerFactory)
}
export const getGhostSharePotFactoryAddress = () => {
  return getAddress(addresses.ghostSharePotFactory)
}
export const getFarmLpAddress = (id: number) => {
  const config = farmsConfig.find((farm) => farm.pid === id)
  return getAddress(config.lpAddresses)
}