import { ChainId, Token } from '@ghost-nft/ghost-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { Address } from 'config/constants/types'
import addresses from 'config/constants/contracts'
import { SerializedToken } from './types'

export const APP_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID,10)

const getTokenAddress = (address: Address): string => {
  return address[APP_CHAIN_ID] ?? address[ChainId.MAINNET]
}

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

const defaultToken = {
  wbnb: new Token(
    APP_CHAIN_ID,
    getTokenAddress(addresses.wbnb),
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  bnb: new Token(
    APP_CHAIN_ID,
    getTokenAddress(addresses.wbnb),
    18,
    'BNB', 
    'BNB', 
    'https://www.binance.com/'
  ),
  usdt: new Token(
    APP_CHAIN_ID,
    getTokenAddress(addresses.usdt),
    18,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  busd: new Token(
    APP_CHAIN_ID,
    getTokenAddress(addresses.busd),
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  silverGhost: new Token(
    APP_CHAIN_ID,
    getTokenAddress(addresses.silverghost),
    18,
    'SG',
    'Silver Ghost',
    'https://ghostnft.io',
  ),
}

export const mainnetTokens = {
  ...defaultToken,
}

export const testnetTokens = {
  ...defaultToken,
}

export const localnetTokens = {
  ...defaultToken,
}

const tokens = (): TokenList => {

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (APP_CHAIN_ID === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  if (APP_CHAIN_ID === ChainId.LOCALNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: localnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
