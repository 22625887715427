import { ChainId } from '@ghost-nft/ghost-sdk'
import store from 'state'
import { GAS_PRICE_GWEI } from 'state/user/hooks/helpers'
import { APP_CHAIN_ID } from 'config/constants/tokens'

/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = (): string => {
  const state = store.getState()
  const userGas = state.user.gasPrice || GAS_PRICE_GWEI.default
  return APP_CHAIN_ID === ChainId.MAINNET ? userGas : GAS_PRICE_GWEI.testnet
}

export default getGasPrice
