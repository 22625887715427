import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getMasterChefAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getNftMarketAddress,
  getGhostSquadAddress,
  getGhostPotAddress,
  getGhostNftAddress,
  getGhostCollectionFactoryAddress,
  getGhostFeeManagerFactoryAddress,
  getGhostSharePotFactoryAddress
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import silverGhostAbi from 'config/abi/silverGhost.json'
import masterChef from 'config/abi/masterchef.json'
import smartChefAbi from 'config/abi/smartChef.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import nftMarketAbi from 'config/abi/GhostNFTMarket.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import GhostPotAbi from 'config/abi/GhostPot.json'
import GhostNftAbi from 'config/abi/GhostNft.json'
import GhostCollectionFactoryAbi from 'config/abi/GhostCollectionFactory.json'
import GhostFeeManagerAbi from 'config/abi/GhostFeeManager.json'
import GhostFeeManagerFactoryAbi from 'config/abi/GhostFeeManagerFactory.json'
import GhostSharePotAbi from 'config/abi/GhostSharePot.json'
import GhostSharePotFactoryAbi from 'config/abi/GhostSharePotFactory.json'
import GhostSquadAbi from 'config/abi/GhostSquad.json'
import { ChainLinkOracleContract, PancakeProfileContract } from './types'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getSmartChefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(smartChefAbi, getAddress(config.contractAddress), signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, tokens.cake.address, signer)
}
export const getSilverGhostContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(silverGhostAbi, tokens.silverGhost.address, signer)
}
export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer) as PancakeProfileContract
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getNftMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer)
}
export const getGhostSquadContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostSquadAbi, getGhostSquadAddress(), signer)
}
export const getGhostPotContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostPotAbi, getGhostPotAddress(), signer)
}
export const getGhostNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostNftAbi, getGhostNftAddress(), signer)
}
export const getGhostCollectionFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostCollectionFactoryAbi, getGhostCollectionFactoryAddress(), signer)
}
export const getGhostFeeManagerContract = (address:string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostFeeManagerAbi, address, signer)
}
export const getGhostFeeManagerFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostFeeManagerFactoryAbi, getGhostFeeManagerFactoryAddress(), signer)
}
export const getGhostSharePotContract = (address:string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostSharePotAbi, address, signer)
}
export const getGhostSharePotFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(GhostSharePotFactoryAbi, getGhostSharePotFactoryAddress(), signer)
}
export const getErc721CollectionContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(erc721CollectionAbi, address, signer)
}
