export default {
  masterChef: {
    56: '0x25a1774dFBaef8dd46845d3CDbcCf043E7D3Fc87',
    97: '0x19C861774787bdc5214743A3d614E90f0a0b8368',
    1337: '0xE90BedcE34864878f078092918a3768e94EBCA64',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8Ff2c6183dC3D19F64332D1c5542870Dc9F0B81e',
    1337: '0x2CEC19Ac911265662192a4F634f4CC8451462512',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1337: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    1337: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
  },
  nftMarket: {
    56: '0x79485b8989beD6da83c04dcE2f29B40771a4e55D',
    97: '0xf55eDc63D3c61D7AE34eE1Ce41cd1aEa3442f168',
    1337: '0x06E3C4795F046792265517440524054cdF2823D2',
  },
  ghostSquad: {
    56: '0x2206d93842ACEDaC749D4600Ca46E42c9134a6d0',
    97: '0x09B33Ad8CE892360Eb080B631Cd861c9b2858D0a',
    1337: '0x6b0C47d69E9d239568BEB026972e15Ba39eeBf7E',
  },
  ghostRouter: {
    56: '0x74f27e9763d660e1C039191AE23C1C60Cf1C6f86',
    97: '0xb3F415714C3dc9F419f0dC3a8984401974F5bB85',
    1337: '0xd3A6A42c9fe3f764A3D67C3E41f70328e0cE0120',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xa7DDE2DbB0D5454e458383d4E8659a825ADc25e1',
    1337: '0xde64b1B936E11f7C9C645063A23916ED193bD80f',
  },
  silverghost: {
    56: '0x0B5a031e348481AbCb32972C94Cf090f20455CEf',
    97: '0xE285efF75fc3BbbBBC5CFeeD4cdC8899D74c0c33',
    1337: '0x24440f181aD05a4f035f125550F3Fe4561f5667E',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0x86B026F070bD3eb49871678f247a9388448C3c94',
    1337: '0xfC625665730bA54cB5eC88d7c4C25c23aCAAF8b5',
  },
  busd: {
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    97: '0x189e667699b2D9ce852bba233B29F83b8c3DF3a2',
    1337: '0x029a35f14F1e6cc3eEA16BDc8329258531cdA305',
  },
  ghostPot: {
    56: '0x9e260Dab354E29213c64ef46Deb0F340301BB111',
    97: '0x97D0a86ca86Ee7d97C86D1b3EAa0FBAc851826ea',
    1337: '0x8250C98880C48c75daA89c345Fe12d18b29d8580',
  },
  ghostNft: {
    56: '0x4eBFe2C1B757FFb2e3c3a062791fdC87f2A626Fc',
    97: '0xe823dE2ff3BDC25aA69fd8AbBcD77490e6089DD7',
    1337: '0xdD8f6f30158255AC82Cc5357B8CEEe884F3113b6',
  },
  ghostCollectionFactory: {
    56: '0x7862A281Bf24A85eCf28903eD6432d6503FC26dD',
    97: '0x5A72bFD3Df04f3d6C4FF8C42E76A45A50CF1574e',
    1337: '0xF1f96023141440A750DDCFd33b4570a300a66a7F',
  },
  ghostFeeManagerFactory: {
    56: '0x61774C6A54d1d9839C70663A42bAF674204513b9',
    97: '0x5A72bFD3Df04f3d6C4FF8C42E76A45A50CF1574e',
    1337: '0x1c3F29991eF7F4c718d3a0CB92d8997e60f88DA4',
  },
  ghostSharePotFactory: {
    56: '0xDb42d9E756638505A67d2051dC5001EFB1Ff2eFa',
    97: '0x5A72bFD3Df04f3d6C4FF8C42E76A45A50CF1574e',
    1337: '0x9334faCC0bc849f51F1BeC9BB038FB3A3ceBf38D',
  },
}
