import { useEffect,useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { farmsConfig } from 'config/constants'
import { useSlowFresh } from 'hooks/useRefresh'
import { deserializeToken } from 'state/user/hooks/helpers'
import { getSilverGhostContract } from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import smartChefAbi from 'config/abi/smartChef.json'
import allPools from 'config/constants/pools'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.'
import { State, SerializedFarm, DeserializedFarmUserData, DeserializedFarm, DeserializedFarmsState } from '../types'

const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const { lpAddresses, lpSymbol, pid, dual, multiplier, isCommunity, quoteTokenPriceUsdt, tokenPriceUsdt } = farm

  return {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceUsdt,
    tokenPriceUsdt,
    token: deserializeToken(farm.token),
    quoteToken: deserializeToken(farm.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
  }
}

export const usePollFarmsPublicData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const slowRefresh = useSlowFresh()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))
  }, [includeArchive, dispatch, slowRefresh])
}

export const usePollFarmsWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const slowRefresh = useSlowFresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

export const useFarms = (): DeserializedFarmsState => {
  const farms = useSelector((state: State) => state.farms)
  const deserializedFarmsData = farms.data.map(deserializeFarm)
  const { loadArchivedFarmsData, userDataLoaded } = farms
  return {
    loadArchivedFarmsData,
    userDataLoaded,
    data: deserializedFarmsData,
  }
}

export const useFarmFromPid = (pid: number): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return deserializeFarm(farm)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return deserializeFarm(farm)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const { userData } = useFarmFromPid(pid)
  const { allowance, tokenBalance, stakedBalance, earnings } = userData
  return {
    allowance,
    tokenBalance,
    stakedBalance,
    earnings,
  }
}

// Return the base token price for a farm, from a given pid
export const useUSDTPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm.tokenPriceUsdt)
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useUSDTPriceFromPid(farm.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply.gt(0) && farm.lpTotalInQuoteToken.gt(0)) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(farm.lpTotalSupply)
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceViaSilverGhost = (): number => {
  const slowRefresh = useSlowFresh()
  const [usdReward, setUsdReward] = useState<number>()
  useEffect(() => {
    async function fetchAllPoolRewards(){
      const notFinishedPools = allPools.filter(pool => !pool.isFinished)
      const silverGhostContract = getSilverGhostContract()
      const totalSilverGhostSupply = await silverGhostContract.totalSupply()
      const calls = [];
      notFinishedPools.forEach((pool) => {
          const smartChefAddress = getAddress(pool.contractAddress)
          calls.push({
            address: smartChefAddress,
            name: 'rewardPerBlock',
          })
          calls.push({
            address: smartChefAddress,
            name: 'bonusEndBlock',
          })
          calls.push({
            address: smartChefAddress,
            name: 'startBlock',
          })
        })
      const poolInfos = await multicall(smartChefAbi, calls)
      const poolBlockInfos = poolInfos.map((blockBigNumber) => parseInt(blockBigNumber.toString()))
      let remainTotalReward = 0
      for(let i = 0;i < poolBlockInfos.length / 3;i++){
        remainTotalReward += 1 * poolBlockInfos[(3*i)] * (poolBlockInfos[(3*i) + 1] - poolBlockInfos[(3*i) + 2])
      }
      const totalRewardBN = new BigNumber(remainTotalReward.toString())
      const totalSilverGhostSupplyBN = new BigNumber(totalSilverGhostSupply.toString())
      const usdRewardBN = totalRewardBN.div(totalSilverGhostSupplyBN)
      setUsdReward(usdRewardBN.toNumber())
    }
    fetchAllPoolRewards()
  }, [slowRefresh])
  return usdReward
  
  // return usdReward ? usdReward.toNumber() : null;
  // const rawTokenBalances = await multicall(erc20ABI, calls)
  // const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
  //   return new BigNumber(tokenBalance).toJSON()
  // })
  // return parsedTokenBalances
  // const {pools:allPools} = usePools();
  // const totalSilverGhostSupply = useTotalSupply()
  // const slowRefresh = useSlowFresh()
  // const [usdReward, setUsdReward] = useState<BigNumber>()
  // useEffect(() => {
  //   async function fetchPoolReward(pool) {
  //     // next development
  //     const smartChefContract = getSmartChefContract(pool.sousId)
  //     const tokenPerBlock = await smartChefContract.rewardPerBlock();
  //     const startBlock = await smartChefContract.startBlock();
  //     const endBlock = await smartChefContract.bonusEndBlock();
  //     return 1 * (endBlock - startBlock) * parseInt(tokenPerBlock)
  //   }
  //   async function fetchAllPoolRewards(){
  //     const poolRewardPromises = allPools.map((pool) => fetchPoolReward(pool));
  //     const poolRewards = await Promise.all(poolRewardPromises)
  //     let remainTotalReward = 0;
  //     poolRewards.forEach((reward) => {
  //       remainTotalReward += reward
  //     })
  //     setUsdReward(new BigNumber(remainTotalReward).div(totalSilverGhostSupply))
  //   }
  //   fetchAllPoolRewards()
  // }, [slowRefresh,allPools,totalSilverGhostSupply])
  // return usdReward ? usdReward.toNumber() : null;
}